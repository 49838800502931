import './App.css';
import { Route, Routes } from 'react-router-dom';
import './i18n';
import NavBar from './components/NavBar';
import Home from './pages/Home';
import About from './pages/About';
import Brands from './pages/Brands';
import Faq from './pages/Faq';
import Contact from './pages/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <NavBar />
      <div className="pages">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about/" element={<About />} />
          <Route path="/brands/" element={<Brands />} />
          <Route path="/FAQ/" element={<Faq />} />
          <Route path="/contact/" element={<Contact />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
