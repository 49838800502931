/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './NavBar.css';
import Logo from '../assets/images/Logos/dc_logo_long-white.svg';

function NavBar() {
  const lngs = {
    en: { nativeName: 'EN' },
    sp: { nativeName: 'SP' },
  };

  const { t, i18n } = useTranslation();
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  return (
    <div>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink to="/" className="nav-logo">
            <img src={Logo} alt="Digital Company Logo" className="logo-img" />
          </NavLink>

          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className="nav-item">
              <NavLink to="/" className="nav-links" onClick={handleClick}>
                {t('navbar.Home')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/about/" className="nav-links" onClick={handleClick}>
                {t('navbar.About')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/brands/" className="nav-links" onClick={handleClick}>
                {t('navbar.Brands')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/FAQ/" className="nav-links" onClick={handleClick}>
                {t('navbar.FAQ')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/contact/" className="nav-links" onClick={handleClick}>
                {t('navbar.Contact')}
              </NavLink>
            </li>
            <li>
              <div className="lang-switch">
                <i className="fas fa-globe language" />
                {Object.keys(lngs).map((lng) => (
                  <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal', color: i18n.resolvedLanguage === lng ? '#0086f8' : 'white' }} className="lang-choice" type="submit" onClick={() => i18n.changeLanguage(lng)}>
                    {lngs[lng].nativeName}
                  </button>
                ))}
              </div>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick} onKeyDown={handleClick} role="button" tabIndex="0">
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
