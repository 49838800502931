import React from 'react';
import { useTranslation } from 'react-i18next';
import Question from '../components/Question';
import './Faq.css';

function Faq() {
  const { t } = useTranslation();

  return (
    <div className="faq">
      <h2 className="faq-title">FAQ</h2>
      <h4>Frequently Asked Questions</h4>
      <p className="faq-description">
        We have gathered the most common questions we get
        <br />
        regarding Digital Company and its services.
      </p>
      <div className="questions-container">
        <div className="question-mark">?</div>
        <h5>General</h5>
      </div>
      <div className="question-card">
        <Question
          question={t('faq.questions.question0')}
          answer={t('faq.questions.answer0')}
        />
      </div>
      <div className="question-card">
        <Question
          question={t('faq.questions.question1')}
          answer={t('faq.questions.answer1')}
        />
      </div>
      <div className="question-card">
        <Question
          question={t('faq.questions.question2')}
          answer={t('faq.questions.answer2')}
        />
      </div>
      <div className="question-card">
        <Question
          question={t('faq.questions.question3')}
          answer={t('faq.questions.answer3')}
        />
      </div>
      <div className="question-card">
        <Question
          question={t('faq.questions.question4')}
          answer={t('faq.questions.answer4')}
        />
      </div>
      <div className="question-card">
        <Question
          question={t('faq.questions.question5')}
          answer={t('faq.questions.answer5')}
        />
      </div>
    </div>
  );
}

export default Faq;
