import React from 'react';
import ContactForm from '../components/ContactForm';

function Contact() {
  return (
    <div>
      <h2>
        Contact Us!
      </h2>
      <ContactForm />
    </div>
  );
}

export default Contact;
