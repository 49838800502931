import React from 'react';
import { useTranslation } from 'react-i18next';
import OfficeImage from '../assets/images/office.png';
import WorldImage from '../assets/images/world.png';
import './About.css';

function About() {
  const { t } = useTranslation();

  return (
    <div className="about">
      <h2 className="about-title">{t(`about.title`)}</h2>
      <div className="about-main">
        <div>
          <img src={WorldImage} />
        </div>
        <div>
          <div className="about-subtitle">{t(`about.main.title`)}</div>
          <p>{t(`about.main.p0`)}</p>
          <p>{t(`about.main.p1`)}</p>
        </div>
      </div>
      <div className="about-main">
        <div>
          <div className="about-subtitle">{t(`about.ourMision.title`)}</div>
          <p>{t(`about.ourMision.p0`)}</p>
          <ul>
            <li>
              <strong>{t(`about.ourMision.li_0_strong`)}</strong> –
              {t(`about.ourMision.li_0`)}
            </li>
            <li>
              <strong>{t(`about.ourMision.li_1_strong`)}</strong> –
              {t(`about.ourMision.li_1`)}
            </li>
            <li>
              <strong>{t(`about.ourMision.li_2_strong`)}</strong> –
              {t(`about.ourMision.li_2`)}
            </li>
          </ul>
        </div>
        <div>
          <img src={OfficeImage} />
        </div>
      </div>

      <div className="about-our-services">
        <h4>{t(`about.ourServices.title`)}</h4>
        <div className="cards">
          <div className="card">
           {/*  <div className="value-img-container">
              <img
                className="value-img"
                src="/static/media/price.171cffe8a7dfba3ce4a0.png"
                alt="value"
              />
            </div> */}
            <h3 className="card-title">{t(`about.ourServices.sourcing.title`)}</h3>
            <p className="card-text">{t(`about.ourServices.sourcing.p0`)}</p>
          </div>
          <div className="card">
           {/*  <div className="value-img-container">
              <img
                className="value-img"
                src="/static/media/price.171cffe8a7dfba3ce4a0.png"
                alt="value"
              />
            </div> */}
            <h3 className="card-title">{t(`about.ourServices.wholesaleDistribution.title`)}</h3>
            <p className="card-text">{t(`about.ourServices.wholesaleDistribution.p0`)}</p>
          </div>
          <div className="card">
            {/* <div className="value-img-container">
              <img
                className="value-img"
                src="/static/media/price.171cffe8a7dfba3ce4a0.png"
                alt="value"
              />
            </div> */}
            <h3 className="card-title">{t(`about.ourServices.apiIntegration.title`)}</h3>
            <p className="card-text">{t(`about.ourServices.apiIntegration.p0`)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
