/* eslint-disable global-require */
import React from 'react';
import './BrandCard.css';

function BrandCard() {
  const brandArray = [
    {
      number: 1,
      title: 'Amazon',
      image: 'amazon-gift-card',
    },
    {
      number: 2,
      title: 'Blizzard',
      image: 'blizzard-gift-card',
    },
    {
      number: 3,
      title: 'EA',
      image: 'EA-play-gift-card',
    },
    {
      number: 4,
      title: 'Garena',
      image: 'garena-freefire-points',
    },
    {
      number: 5,
      title: 'Google Play',
      image: 'google-play-gift-card',
    },
    {
      number: 6,
      title: 'Hulu',
      image: 'hulu-gift-card',
    },
    {
      number: 7,
      title: 'iTunes',
      image: 'itunes-gift-card',
    },
    {
      number: 8,
      title: 'Microsoft',
      image: 'microsoft-windows-key',
    },
    {
      number: 9,
      title: 'Minecraft',
      image: 'minecraft-coins',
    },
    {
      number: 10,
      title: 'Netflix',
      image: 'netflix-gift-card',
    },
    {
      number: 11,
      title: 'Nintendo',
      image: 'nintendo-gift-card',
    },
    {
      number: 12,
      title: 'Paramount',
      image: 'paramount-plus-gift-card',
    },
    {
      number: 13,
      title: 'PSN',
      image: 'PSN-gift-card',
    },
    {
      number: 14,
      title: 'PSN PLUS',
      image: 'PSN-Plus',
    },
    {
      number: 15,
      title: 'Pubg',
      image: 'pubg-points',
    },
    {
      number: 16,
      title: 'Razer',
      image: 'razer-gold-gift-card',
    },
    {
      number: 17,
      title: 'Roblox',
      image: 'roblox-gift-card',
    },
    {
      number: 18,
      title: 'Steam',
      image: 'steam-gift-card',
    },
    {
      number: 19,
      title: 'Uber',
      image: 'uber-gift-card',
    },
    {
      number: 20,
      title: 'XBOX',
      image: 'xbox-gift-card',
    },
  ];
  return (
    <div className="brand-card-container">
      <div className="brand-card">
        <h3 className="our-brands">Our Brands</h3>
        <div className="mini-cards">
          {brandArray.map((item) => (
            <div key={item.number} className="image-container">
              <img
                src={require(`../assets/images/brands/${item.image}.png`)}
                alt={item.title}
                className="brand-image"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

// <i className="fab fa-brands fa-amazon" />
// <i className="fab fa-brands fa-aws" />
// <i className="fab fa-brands fa-bitcoin" />
// <i className="fab fa-brands fa-spotify" />
// <i className="fab fa-brands fa-apple" />
// <i className="fab fa-brands fa-apple-pay" />

export default BrandCard;
