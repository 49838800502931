import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import FooterLogo from '../assets/images/Logos/dc-logo-square-white.svg';
import './Footer.css';

function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="footer-logo">
        <NavLink to="/" className="nav-logo">
          <img src={FooterLogo} alt="Digital Company Logo" />
        </NavLink>
      </div>
      <div>
        <NavLink className="nav-links" to="/">{t('navbar.Home')}</NavLink>
        <NavLink className="nav-links" to="/About/">{t('navbar.About')}</NavLink>
        <NavLink className="nav-links" to="/Brands/">{t('navbar.Brands')}</NavLink>
        <NavLink className="nav-links" to="/FAQ/">{t('navbar.FAQ')}</NavLink>
        <NavLink className="nav-links" to="/Contact/">{t('navbar.Contact')}</NavLink>
      </div>
      <div className="footer-text">
        <div className="social-links">
          <NavLink to="/" className="social-links"><i className="fab fa-linkedin-in" /></NavLink>
          <NavLink to="/" className="social-links"><i className="fab fa-skype" /></NavLink>
          <NavLink to="/" className="social-links"><i className="fas fa-envelope" /></NavLink>
        </div>
        <NavLink to="/" className="nav-links">{t('footer.terms')}</NavLink>
        <p>{`© Digital Company 2022. ${t('footer.rights')}`}</p>
      </div>
    </div>
  );
}

export default Footer;
