import React from 'react';
import { PropTypes } from 'prop-types';
import './Card.css';

function Card({ image, title, description }) {
  return (
    <div className="card">
      <div className="value-img-container">
        <img className="value-img" src={image} alt="value" />
      </div>
      <h3 className="card-title">{title}</h3>
      <p className="card-text">{description}</p>
    </div>
  );
}

Card.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Card;
