import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n

  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    lng: i18n.options.lng,
    // fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    resources: {
      en: {
        translation: {
          navbar: {
            Home: 'Home',
            About: 'About',
            Brands: 'Brands',
            FAQ: 'FAQ',
            Contact: 'Contact Us',
          },
          footer: {
            terms: 'Terms and conditions',
            rights: 'All rights reserved',
          },
          home: {
            Global:
              'Global Distributor Of Digital Gift Cards, Video Games And Software',
          },
          benefits: {
            Price: 'Better Price',
            PriceDesc:
              'Encontrarás los precios más bajos para poder llevar a cabo tu negocio al siguente nivel, precios estables y constantes.',
            Brands: 'All Brands in One Place',
            BrandsDesc:
              'Encontrarás las mejores marcas con sus respectivos productos más demandados en el mercado digital, mantente actualizado y ofrecele la mejor variedad de productos a tus clientes.',
            Safe: '100% Safe',
            SafeDesc:
              'Productos de empresas con trayectoria, la gran mayoría trabaja directamente con las marcas/publishers, encontrarás solo productos originales.',
            Personalized: 'Asistencia Personalizada',
            PersonalizedDesc:
              'Cuenta con nuestra ayuda para cada caso que necesites de forma personalizada para cada situación en específico, nos preocupamos de mantener una relación cercana con nuestros partners.',
            Technology: 'Tecnología de Vanguardia',
            TechnologyDesc:
              'A la vanguardia en lo que respecta al mundo digital, contamos con sistemas automatizados como el sistema API, variedad en metodos de pago y más!',
            Digital: '100% Digital',
            DigitalDesc:
              'Todos nuestros productos son digitales por lo cual garantiza una entrega en el momento de la compra 24/7, no esperes de más, no hagas esperar a tu cliente, el mundo de ahora es DIGITAL.',
            B2B: 'B2B/B2C',
            B2BDesc:
              'Con nuestro modelo de negocio, el servicio que te brindamos te va dar la facilidad de poder expandir tu negocio al vender usando los dos canales de ventas en este rubro B2B/B2C ( Distribuidor/Cliente final ), 2 canales de ganancias, transforma tu empresa, llévala al siguente nivel.',
          },
          about: {
            title: 'About Us',
            main: {
              title: 'Global Digital World',
              p0: 'Since its incorporation in 2019, Digital Company has established itself as a trusted global wholesale distributor partner in the digital gift card industry and video game industry. Our team are expert in the industry across sales, purchasing, management and operations, have over 10 years of combined experience in digital gift cards, video games and software distribution.',
              p1: 'We source, manage and distribute digital gift cards, video games and software of many publishers and companies, to our network of online retail and gaming partners across the world (Europe , Latam, Asia, America, etc).',
            },
            ourMision: {
              title: 'Our Mision',
              p0: 'Digital Company only deals exclusively with registered business entities to ensure that our clients are able to sell digital products into the market at the best and most competitive pricing.',
              li_0_strong: 'Wholesale Distribution',
              li_0: 'We facilitate the distribution over 1.5 Million digital products per year to clients across 25 Countries through a variety of channels including the latest in API integrations',
              li_1_strong: 'Main Brands',
              li_1: 'We offer a source and stock of a wide variety of digital products including e-gift cards, video games, streaming services and software solutions from leading publishers',
              li_2_strong: 'Loyalty Partners',
              li_2: 'We have trusted long term relationships with wholesaler distributors, online game & digital gift card retailers across the world. always committing ourselves to offer you the most requested products on the market',
            },
            ourServices: {
              title: "Our Services",
              sourcing: {
                title: 'Sourcing',
                p0: 'We guarantee to our client have the better prices, stock and variety of products in the digital world.',
              },
              wholesaleDistribution: {
                title: 'Wholesale Distribution',
                p0: 'We offer the widest connection in what is the distribution network of digital codes, video games and software. Giving the possibility for all our clients to distribute digital products in their respective businesses.',
              },
              apiIntegration: {
                title: 'API Integration',
                p0: 'We guarantee our collaborators and clients, to be at the Vanguard of technology, so that they can provide the best services to their clients with automatic deliveries and the safest possible.',
              },
            },
          },
          faq: {
            questions: {
              question0: 'What method payments we accept?',
              answer0:
                'Flexible payment options, including USD, EUR as well as many other international currencies. This saves our customers time and FX fees, allowing us the flexibility and competitive edge in an industry where fast payment clearing and delivery of products is of the utmost importance.',
              question1: 'Delay in orders',
              answer1:
                'Our delivery times vary depending on the availability and stock levels at the time of ordering ( You can ask your account manager about delay in specific products ), if you order with system api the delivered is instant.',
              question2: 'Do you supply products for all regions/Globals?',
              answer2:
                'Yes, we do. You can choose the regions of each products inside of the category of our catalogue.',
              question3: 'Can I Cancel An Order?',
              answer3:
                'You can only if the payment wasnt send by you, if the payment arrived in our account is not posible to cancel it. If we only send you an invoice and you didnt send the money, you can cancel it anytime.',
              question4: 'How Do I Open An Account With Digital Company?',
              answer4:
                'You have to request it by email in the section "contact us ", we are going to send the requiredments to you and you haveto Fill the Merchant Application Form, once approved you can start sourcing from our extensive selection of digital gift card products.',
              question5: 'What Is Your Return Policy? ',
              answer5:
                'We do not accept returns of unwanted or non-faulty items. Faulty goods may need to be proven by sending us a picture of the damaged product, along with a returns form for inspection. If liability is accepted, we will refund you for the cost of the goods or resend new products. Liability will never exceed the value of the goods.',
            },
          },
        },
      },

      sp: {
        translation: {
          navbar: {
            Home: 'Inicio',
            About: 'Información',
            Brands: 'Marcas',
            FAQ: 'Preguntas',
            Contact: 'Contacto',
          },
          footer: {
            terms: 'Términos y condiciones',
            rights: 'Derechos reservados.',
          },
          home: {
            Global: 'Distribuidor Global de Tarjetas y Software Digital',
          },
          benefits: {
            Price: 'Mejores precios',
            PriceDesc:
              'Encontrarás los precios más bajos para poder llevar a cabo tu negocio al siguente nivel, precios estables y constantes.',
            Brands: 'Todas las marcas en un solo lugar',
            BrandsDesc:
              'Encontrarás las mejores marcas con sus respectivos productos más demandados en el mercado digital, mantente actualizado y ofrecele la mejor variedad de productos a tus clientes.',
            Safe: '100% Safe',
            SafeDesc:
              'Productos de empresas con trayectoria, la gran mayoría trabaja directamente con las marcas/publishers, encontrarás solo productos originales.',
            Personalized: 'Asistencia Personalizada',
            PersonalizedDesc:
              'Cuenta con nuestra ayuda para cada caso que necesites de forma personalizada para cada situación en específico, nos preocupamos de mantener una relación cercana con nuestros partners.',
            Technology: 'Tecnología de Vanguardia',
            TechnologyDesc:
              'A la vanguardia en lo que respecta al mundo digital, contamos con sistemas automatizados como el sistema API, variedad en metodos de pago y más!',
            Digital: '100% Digital',
            DigitalDesc:
              'Todos nuestros productos son digitales por lo cual garantiza una entrega en el momento de la compra 24/7, no esperes de más, no hagas esperar a tu cliente, el mundo de ahora es DIGITAL.',
            B2B: 'B2B/B2C',
            B2BDesc:
              'Con nuestro modelo de negocio, el servicio que te brindamos te va dar la facilidad de poder expandir tu negocio al vender usando los dos canales de ventas en este rubro B2B/B2C ( Distribuidor/Cliente final ), 2 canales de ganancias, transforma tu empresa, llévala al siguente nivel.',
          },
          about: {
            title: 'Sobre nosotros',
            main: {
              title: 'Mundo Digital Global',
              p0: 'Desde su incorporación en 2019, Digital Company se ha establecido como un socio distribuidor mayorista global de confianza en la industria de las tarjetas de regalo digitales y la industria de los videojuegos. Nuestro equipo es experto en la industria en ventas, compras, administración y operaciones, tiene más de 10 años de experiencia combinada en tarjetas de regalo digitales, videojuegos y distribución de software.',
              p1: 'Obtenemos, administramos y distribuimos tarjetas de regalo digitales, videojuegos y software de muchos editores y empresas, a nuestra red de socios minoristas y de juegos en línea en todo el mundo (Europa, Latam, America, Asia, etc.).',
            },
            ourMision: {
              title: 'Nuestra misión',
              p0: 'Digital Company solo trata exclusivamente con entidades comerciales registradas para garantizar que nuestros clientes puedan vender productos digitales en el mercado al precio mejor y más competitivo.',
              li_0_strong: 'Distribución al por mayor',
              li_0: 'Facilitamos la distribución de más de 1,5 millones de productos digitales por año a clientes en 25 países a través de una variedad de canales que incluyen lo último en integraciones de API.',
              li_1_strong: 'Principales Marcas',
              li_1: 'Ofrecemos una fuente y existencias de una amplia variedad de productos digitales, incluidas tarjetas de regalo electrónicas, videojuegos, servicios de transmisión y soluciones de software de editores líderes.',
              li_2_strong: 'Socios de lealtad',
              li_2: 'Tenemos relaciones de confianza a largo plazo con distribuidores mayoristas, minoristas de juegos en línea y tarjetas de regalo digitales en todo el mundo. comprometiéndonos siempre en ofrecerte los productos más solicitados en el mercado',
            },
            ourServices: {
              sourcing: {
                title: 'Abastecimiento',
                p0: 'Garantizamos a nuestro cliente tener los mejores precios, stock y variedad de productos en el mundo digital.',
              },
              wholesaleDistribution: {
                title: 'Distribución al por mayor',
                p0: 'Ofrecemos la más amplia conexión en lo que es la red de distribución de códigos digitales, videojuegos y software. Dando la posibilidad a todos nuestros clientes de distribuir productos digitales en sus respectivos negocios.',
              },
              apiIntegration: {
                title: 'Integración de API',
                p0: 'Garantizamos a nuestros colaboradores y clientes, estar a la Vanguardia de la tecnología, para que puedan brindar los mejores servicios a sus clientes con entregas automáticas y lo más seguras posibles.',
              },
            },
          },
          faq: {
            questions: {
              question0: '¿Qué método de pago aceptamos?',
              answer0:
                'Opciones de pago flexibles, incluidos USD, EUR y muchas otras monedas internacionales. Esto ahorra tiempo y tarifas de cambio de divisas a nuestros clientes, lo que nos permite la flexibilidad y la ventaja competitiva en una industria donde la compensación rápida de pagos y la entrega de productos es de suma importancia.',
              question1: 'Retraso en los pedidos',
              answer1:
                'Nuestros tiempos de entrega varían dependiendo de la disponibilidad y los niveles de stock al momento de realizar el pedido (puede consultar a su gerente de cuenta sobre la demora en productos específicos), si realiza el pedido con el sistema api, la entrega es instantánea.',
              question2:
                '¿Suministra productos para todas las regiones/Globales?',
              answer2:
                'Sí. Puede elegir las regiones de cada producto dentro de la categoría de nuestro catálogo.',
              question3: '¿Puedo cancelar un pedido?',
              answer3:
                'Solo puede hacerlo si el pago no fue enviado por usted, si el pago llegó a nuestra cuenta no es posible cancelarlo. Si solo te enviamos una factura y no enviaste el dinero, puedes cancelarlo en cualquier momento.',
              question4: '¿Cómo abro una cuenta con Digital Company?',
              answer4:
                'Debe solicitarlo por correo electrónico en la sección "contáctenos", le enviaremos los requisitos y debe completar el Formulario de solicitud de comerciante, una vez aprobado, puede comenzar a obtener de nuestra amplia selección de productos de tarjetas de regalo digitales.',
              question5: '¿Cuál es su política de devolución? ',
              answer5:
                'No aceptamos devoluciones de artículos no deseados o no defectuosos. Es posible que sea necesario probar los productos defectuosos enviándonos una imagen del producto dañado, junto con un formulario de devolución para su inspección. Si se acepta la responsabilidad, le reembolsaremos el costo de los bienes o le enviaremos nuevos productos. La responsabilidad nunca excederá el valor de los bienes.',
            },
          },
        },
      },
    },
    // lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
  });
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
// const resources = {
//   en: {
//     translation: {
//       description: {
//         Home: 'Home',
//       },
//     },
//   },
//   sp: {
//     translation: {
//       description: {
//         Home: 'Inicio',
//       },
//     },
//   },
// };

export default i18n;
