import React from 'react';
// import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import './ContactForm.css';

function ContactForm() {
  // const initialFValues = {
  // };

  // const [values, setValues] = useState();

  return (
    <div className="box">
      <div className="my-form">
        <TextField className="form-field" id="name-field" label="Name" variant="outlined" required />
        <TextField className="form-field" id="email-field" label="Email" variant="outlined" required />
        <TextField className="form-field" id="company-field" label="Company" variant="outlined" />
        <TextField className="form-field" id="website-field" label="Website" variant="outlined" />
        <TextField className="form-field" id="description-field" label="Description" variant="filled" multiline rows={5} required />
        <button type="submit" className="action-button">Contact Us</button>
      </div>
    </div>
  );
}

export default ContactForm;
