import React, { useState } from 'react';
import './Question.css';
import PropTypes from 'prop-types';

function Question({ question, answer }) {
  const [expanded, setExpanded] = useState(false);
  const handleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="questions-container">
      <div className="question">
        <h5 className="question-title">{question}</h5>
        <button type="button" className="expand" onClick={handleClick}>
          {expanded ? '-' : '+'}
        </button>
      </div>
      <div className="answer">
        {expanded && <p className="answer-text">{answer}</p>}
      </div>
    </div>
  );
}

Question.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default Question;
