import React from 'react';
import { useTranslation } from 'react-i18next';
import HeaderImage from '../assets/images/BANNER-DIGITAL-COMPANY-2.png';
import Card from '../components/Card';
import './Home.css';
import Box from '../assets/images/caja.png';
import BrandCard from '../components/BrandCard';
import Price from '../assets/images/icons/price.png';
import Brands from '../assets/images/icons/brands.png';
import Safe from '../assets/images/icons/safe.png';
import Personalized from '../assets/images/icons/personalized.png';
import Technology from '../assets/images/icons/technology.png';
import Digital from '../assets/images/icons/digital.png';
import B2B from '../assets/images/icons/b2b.png';

function Home() {
  const { t } = useTranslation();
  return (
    <div className="home">
      <div className="banner-div">
        <img className="home-image" src={HeaderImage} alt="Header" />
        <div className="card-container">
          <h2 className="main-description">{t('home.Global')}</h2>
          <p className="description-text">
            We Specialize in the sourcing and distribution of digital products
            <br />
            to online retail and gaming partners across the globe.
          </p>

          <div className="button-container">
            <button type="button" className="action-button">
              Contact Us
            </button>
            <button type="button" className="border-button">
              More Info
            </button>
          </div>

          <div className="benefits">
            <Card
              image={Price}
              title={t('benefits.Price')}
              description={t('benefits.PriceDesc')}
            />
            <Card
              image={Brands}
              title={t('benefits.Brands')}
              description={t('benefits.BrandsDesc')}
            />
            <Card
              image={Safe}
              title={t('benefits.Safe')}
              description={t('benefits.SafeDesc')}
            />
            <Card
              image={Personalized}
              title={t('benefits.Personalized')}
              description={t('benefits.PersonalizedDesc')}
            />
            <Card
              image={Technology}
              title={t('benefits.Technology')}
              description={t('benefits.TechnologyDesc')}
            />
            <Card
              image={Digital}
              title={t('benefits.Digital')}
              description={t('benefits.DigitalDesc')}
            />
            <Card
              image={B2B}
              title={t('benefits.B2B')}
              description={t('benefits.B2BDesc')}
            />
          </div>

          <div className="second-description">
            <h3>Wide range of digital products and brands</h3>
          </div>
          <div className="second-description-text">
            <p className="description-paragraph">
              We regularly add new brands and products to our extensive
              catalogue of digital goods. We consult with local experts who
              recommend up-and-coming high-demand products for us and help us
              create new business opportunities in different countries. Brands
              like to work with us because we put their products in the
              spotlight and conquer new markets for them.
            </p>
          </div>
          <BrandCard />
        </div>
      </div>
      <div className="divider">
        <div className="img-container">
          <img src={Box} alt="icon" className="divider-img" />
          <h3 className="divider-title">
            Digital card sales is predicted to hit 698.2 billion $ in 2024
            worldwide.
          </h3>
          <button type="button" className="action-button">
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
}

export default Home;
