import React from 'react';
import './Brands.css';

function Brands() {
  const brandArray = [
    {
      number: 1,
      title: 'Amazon',
      image: 'amazon-gift-card',
    },
    {
      number: 2,
      title: 'Blizzard',
      image: 'blizzard-gift-card',
    },
    {
      number: 3,
      title: 'EA',
      image: 'EA-play-gift-card',
    },
    {
      number: 4,
      title: 'Garena',
      image: 'garena-freefire-points',
    },
    {
      number: 5,
      title: 'Google Play',
      image: 'google-play-gift-card',
    },
    {
      number: 6,
      title: 'Hulu',
      image: 'hulu-gift-card',
    },
    {
      number: 7,
      title: 'iTunes',
      image: 'itunes-gift-card',
    },
    {
      number: 8,
      title: 'Microsoft',
      image: 'microsoft-windows-key',
    },
    {
      number: 9,
      title: 'Minecraft',
      image: 'minecraft-coins',
    },
    {
      number: 10,
      title: 'Netflix',
      image: 'netflix-gift-card',
    },
    {
      number: 11,
      title: 'Nintendo',
      image: 'nintendo-gift-card',
    },
    {
      number: 12,
      title: 'Paramount',
      image: 'paramount-plus-gift-card',
    },
    {
      number: 13,
      title: 'PSN',
      image: 'PSN-gift-card',
    },
    {
      number: 14,
      title: 'PSN PLUS',
      image: 'PSN-Plus',
    },
    {
      number: 15,
      title: 'Pubg',
      image: 'pubg-points',
    },
    {
      number: 16,
      title: 'Razer',
      image: 'razer-gold-gift-card',
    },
    {
      number: 17,
      title: 'Roblox',
      image: 'roblox-gift-card',
    },
    {
      number: 18,
      title: 'Steam',
      image: 'steam-gift-card',
    },
    {
      number: 19,
      title: 'Uber',
      image: 'uber-gift-card',
    },
    {
      number: 20,
      title: 'XBOX',
      image: 'xbox-gift-card',
    },
  ];

  return (
    <div className="brands-page">
      <h2 className="brands-title">Our Brands</h2>
      <p className="brands-description">
        We stock over 60 leading global brands across a range of platforms such
        as gaming, streaming
        <br />
        services and software. Our range includes PSN, Xbox, iTunes, Fortnite,
        Netflix, Steam, PUBG,
        <br />
        Valorant, Blizzard, Amazon, Google Play, Nintendo, EA Play and many
        more.
      </p>
      <div className="brands-container-b">
        {brandArray.map((item) => (
          <div key={item.number} className="image-container-b">
            <img
              // eslint-disable-next-line global-require
              src={require(`../assets/images/brands/${item.image}.png`)}
              alt={item.title}
              className="brand-image-b"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Brands;
